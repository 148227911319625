const Dropdown = function (el) {
    let dropdown = el,
        btn = el.querySelector('.dropdown__btn');

    btn.addEventListener('click', function (e) {
        e.preventDefault();

        if (dropdown.classList.contains('hover') && window.innerWidth > 1024) {
            return false;
        }

        if (!dropdown.classList.contains('show')) {
            dropdown.classList.add('show');
            window.addEventListener("click", windowOnClick);
        } else {
            dropdown.classList.remove('show');
            window.removeEventListener("click", windowOnClick);
        }
    });

    function windowOnClick(e) {
        if (!dropdown.contains(e.target)) {
            dropdown.classList.remove('show');
            window.removeEventListener("click", windowOnClick);
        }
    }
};

/*Selectize options*/
const selectizeSingleOptions = {
    persist: false,
    create: false,
    onChange: function () {
        let event = new Event('change');
        this.$input[0].dispatchEvent(event);
    }
};
const selectizeMultipleOptions = {
    plugins: ['remove_button', 'silent_remove', 'stop_backspace_delete'],
    onChange: function () {
        if (this.$input[0].attributes.placeholder) {
            this.$control_input[0].placeholder = this.$input[0].attributes.placeholder.value
        }
        let event = new Event('change');
        this.$input[0].dispatchEvent(event);

        if (this.$input[0].classList.contains('one-line')) {
            this.$control[0].scrollLeft = this.$control[0].scrollWidth - this.$control[0].clientWidth
        }
    }
};

function selectizeInit() {
    $('select.selectize-here:not([multiple]):not(.selectized)').selectize(selectizeSingleOptions);
    $('select.selectize-here[multiple]:not(.selectized)').selectize(selectizeMultipleOptions);
}

//Selectize custom plugins
Selectize.define('silent_remove', function (options) {
    let self = this;

    // defang the internal search method when remove has been clicked
    this.on('item_remove', function () {
        this.plugin_silent_remove_in_remove = true;
    });

    this.search = (function () {
        let original = self.search;
        return function () {
            if (typeof (this.plugin_silent_remove_in_remove) != "undefined") {
                // re-enable normal searching
                delete this.plugin_silent_remove_in_remove;
                return {
                    items: {},
                    query: [],
                    tokens: []
                };
            } else {
                return original.apply(this, arguments);
            }
        };
    })();
});
Selectize.define("stop_backspace_delete", function (options) {
    let self = this;

    this.deleteSelection = (function () {
        let original = self.deleteSelection;

        return function (e) {
            if (!e || e.keyCode !== 8) {
                return original.apply(this, arguments);
            }

            return false;
        };
    })();
});

//Make body unscrollable
function bodyUnscrollable() {
    const body = document.querySelector('body');
    const mainWrapper = document.querySelector('.main-wrapper');

    if (!body.classList.contains('no-scroll')) {
        let scrollTop = window.pageYOffset;

        mainWrapper.style.marginTop = -scrollTop + 'px';
        body.classList.add('no-scroll');
    }
}

//Make body scrollable
function bodyScrollable() {
    const body = document.querySelector('body');
    const mainWrapper = document.querySelector('.main-wrapper');

    if (body.classList.contains('no-scroll')) {
        let scrollTop = mainWrapper.style.marginTop.slice(0, -2);

        mainWrapper.style.marginTop = 0;
        body.classList.remove('no-scroll');
        window.scrollTo(0, -scrollTop);
    }
}

(function () {
    /*Init dropdown*/
    document.querySelectorAll('.dropdown').forEach(function (el) {
        let dropdown = new Dropdown(el);
    });
    /**/

    /*Fixed header and scroll up btn behaviour*/
    toggleScrollElements();

    window.addEventListener('scroll', function () {
        toggleScrollElements();
    });

    function toggleScrollElements() {
        if (window.pageYOffset > 0) {
            document.querySelector('header').classList.add('fixed');
            document.querySelector('.scroll-up').classList.add('visible');
        } else {
            document.querySelector('header').classList.remove('fixed');
            document.querySelector('.scroll-up').classList.remove('visible');
        }
    }
    /**/

    /*Scroll up*/
    document.querySelector('.scroll-up').addEventListener('click', function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    });
    /**/

    /*Menu btn*/
    document.querySelector('#menuBtn').addEventListener('click', function (e) {
        e.preventDefault();
       if (window.innerWidth <= 1024) {
           e.target.classList.toggle('active');
           if (e.target.classList.contains('active')) {
               document.querySelector('.mobile-menu').style.display = 'block';
               bodyUnscrollable();
           } else {
               document.querySelector('.mobile-menu').style.display = 'none';
               bodyScrollable();
           }
       }
    });
    /**/
})();

$(function () {
    // Selectize init
    selectizeInit();
});